<template>
  <v-card flat class="pa-3 mt-2">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row>
              <v-col md="6" cols="12">
                <v-select
                  :items="[
                    { id: 1, descripcion: 'BANCO' },
                    { id: 2, descripcion: 'CAJA' },
                  ]"
                  label="Tipo"
                  item-text="descripcion"
                  item-value="id"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  v-model="datos.id_tipo"
                ></v-select>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  :rules="reglas.codigo"
                  v-model="datos.codigo"
                  :label="'Codigo *'"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :rules="reglas.nombre"
                  v-model="datos.nombre"
                  :label="'Nombre *'"
                  required
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col lg="12" md="12" cols="12">
                <v-text-field-simplemask
                  v-model="datos.telefono"
                  v-bind:label="'Telefono'"
                  v-bind:properties="{
                    outlined: true,
                    clearable: false,
                    dense: true,
                    rules: reglas.telefono,
                  }"
                  v-bind:options="{
                    inputMask: '(####) ### ## ##',
                    outputMask: '###########',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: false,
                    lowerCase: true,
                  }"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <h4>Imagen</h4>
                <CropImagen
                  :indRecortar="false"
                  ref="CropImagenBaseRef"
                  :urlImgDefault="require('@/assets/images/logos/arty4.png')"
                  :urlImgValue="datos.img_temp"
                  :recorteWidth="300"
                  :recorteHeight="200"
                ></CropImagen>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <h4>Imagen icon</h4>
                <CropImagen
                  :indRecortar="false"
                  ref="CropImagenBaseRef2"
                  :urlImgDefault="require('@/assets/images/logos/arty4.png')"
                  :urlImgValue="datos.img_icon_temp"
                  :recorteWidth="300"
                  :recorteHeight="300"
                ></CropImagen>
                {{ datos.img_icon_temp }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-divider class="mb-4"></v-divider>
            <v-btn small v-if="datos.id > 0" color="secondary" class="me-3" @click="resetear()"> Resetear </v-btn>
            <v-btn small color="secondary" class="me-3" @click="limpiar()"> Limpiar </v-btn>
            <v-btn small color="primary" class="me-3" @click="GuardarBanco()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiCloudSearch } from '@mdi/js'
import SeguridadService from '@/api/servicios/SeguridadService'
import CropImagen from '@/components/CropImagen.vue'
import store from '@/store'
import config from '@/api/config'
import CuentasService from '@/api/servicios/CuentasService'
import CropImagenBase64 from '@/components/CropImagenBase64.vue'
export default {
  components: { CropImagen, CropImagenBase64 },
  props: {
    BancoDatos: Object,
  },
  setup(props, context) {
    const valid = ref(true)
    const datos = ref(JSON.parse(JSON.stringify(props.BancoDatos)))
    const puestos = ref([])
    const form = ref()
    const cargando = ref(false)

    const CropImagenBaseRef = ref(false)
    const CropImagenBaseRef2 = ref(false)
    watch(props, () => {
      resetear()
    })

    const resetear = () => {
      datos.value = JSON.parse(JSON.stringify(props.BancoDatos))

      form.value.resetValidation()
    }
    onBeforeMount(() => {})

    const reglas = {
      id: -1,

      nombre: [
        v => !!v || 'Nombre es requerido',
        v => (v && v.length <= 50 && v.length >= 3) || 'Nombre debe ser valido',
      ],
      codigo: [v => (v && v.length <= 8 && v.length >= 2) || 'codigo debe ser valido'],
      telefono: [
        v =>
          !!v
            ? /(\(0414|0412|0416|0426|0424)?[ -]*([0-9][ -]*){3}([0-9][ -]*){2}[ -]*([0-9][ -]*){2}$/.test(v) ||
              'Participacion Invalida, Ej: 15 o 12.5'
            : true,
      ],
    }

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }

    const limpiar = () => {
      datos.value = {
        id: -1,
        nombre: '',
        telefono: '',
        codigo: '',
        id_tipo: 1,
        img_icon: '',
        img: '', 
        img_temp:'',
        img_icon_temp:''
      }
      form.value.resetValidation()
    }

    const GuardarBanco = async () => {
      try {
        if (validarForm()) {
          console.log('IMAGEN', CropImagenBaseRef.value.urlImg)
          console.log('IMAGEN2', CropImagenBaseRef2.value.urlImg)
          cargando.value = true
          const parametros = {
            ...datos.value,
            id_empleado: store.state.user.id_empleado,
          }
          if (CropImagenBaseRef.value.datos.imgInput) {
            const form = new FormData()
            form.append('file', CropImagenBaseRef.value.datos.imgInput, 'img.jpg')
            //SUBIR IMAGEN
            console.log('asq')

            await CuentasService.subirImgBanco(form)
              .then(response => {
                console.log('subirImgBanco', response.data)
                //imagen = response.data.img
                parametros.img = response.data.img
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                console.log('Termino')
              })
          }
          if (CropImagenBaseRef2.value.datos.imgInput) {
            const form2 = new FormData()
            form2.append('file', CropImagenBaseRef2.value.datos.imgInput, 'img.jpg')
            await CuentasService.subirImgBanco(form2)
              .then(response => {
                console.log('subirImgBanco', response.data)
                //imagen = response.data.img
                parametros.img_icon = response.data.img
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                console.log('Termino')
              })
          }

          CuentasService.nuevoBanco(parametros)
            .then(response => {
              if (response.data.mensaje == 'BANCO GUARDADO CON EXITO') {
                console.log(response.data)
                limpiar()
                context.emit('CargarLista')
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
              } else {
                console.log(response.data)
                store.commit('setAlert', {
                  message: 'UPS, ' + response.data.mensaje,
                  type: 'info',
                })
              }
            })
            .catch(err => {
              console.error(err)
              store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'guardarBancos',
          })
            })
            .finally(() => {
              cargando.value = false
            })
        } else {
          store.commit('setAlert', {
            message: 'Verifique que todos los datos estén completos',
            type: 'warning',
          })
        }
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',
          
          error: error,
            funcion: 'GuardarBanco-banconuevo',
        })
        cargando.value = false
      }
    }

    return {
      icons: {
        mdiCloudSearch,
      },
      valid,
      datos,
      reglas,
      GuardarBanco,
      form,
      limpiar,
      config,
      resetear,
      cargando,
      CropImagenBaseRef,
      CropImagenBaseRef2,
    }
  },
}
</script>