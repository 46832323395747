var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-select',{attrs:{"items":[
                  { id: 1, descripcion: 'BANCO' },
                  { id: 2, descripcion: 'CAJA' } ],"label":"Tipo","item-text":"descripcion","item-value":"id","required":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.datos.id_tipo),callback:function ($$v) {_vm.$set(_vm.datos, "id_tipo", $$v)},expression:"datos.id_tipo"}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.reglas.codigo,"label":'Codigo *',"required":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.datos.codigo),callback:function ($$v) {_vm.$set(_vm.datos, "codigo", $$v)},expression:"datos.codigo"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.reglas.nombre,"label":'Nombre *',"required":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.datos.nombre),callback:function ($$v) {_vm.$set(_vm.datos, "nombre", $$v)},expression:"datos.nombre"}})],1),_c('v-col',{attrs:{"lg":"12","md":"12","cols":"12"}},[_c('v-text-field-simplemask',{attrs:{"label":'Telefono',"properties":{
                  outlined: true,
                  clearable: false,
                  dense: true,
                  rules: _vm.reglas.telefono,
                },"options":{
                  inputMask: '(####) ### ## ##',
                  outputMask: '###########',
                  empty: null,
                  applyAfter: false,
                  alphanumeric: false,
                  lowerCase: true,
                }},model:{value:(_vm.datos.telefono),callback:function ($$v) {_vm.$set(_vm.datos, "telefono", $$v)},expression:"datos.telefono"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('h4',[_vm._v("Imagen")]),_c('CropImagen',{ref:"CropImagenBaseRef",attrs:{"indRecortar":false,"urlImgDefault":require('@/assets/images/logos/arty4.png'),"urlImgValue":_vm.datos.img_temp,"recorteWidth":300,"recorteHeight":200}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('h4',[_vm._v("Imagen icon")]),_c('CropImagen',{ref:"CropImagenBaseRef2",attrs:{"indRecortar":false,"urlImgDefault":require('@/assets/images/logos/arty4.png'),"urlImgValue":_vm.datos.img_icon_temp,"recorteWidth":300,"recorteHeight":300}}),_vm._v(" "+_vm._s(_vm.datos.img_icon_temp)+" ")],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mb-4"}),(_vm.datos.id > 0)?_c('v-btn',{staticClass:"me-3",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.resetear()}}},[_vm._v(" Resetear ")]):_vm._e(),_c('v-btn',{staticClass:"me-3",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.limpiar()}}},[_vm._v(" Limpiar ")]),_c('v-btn',{staticClass:"me-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.GuardarBanco()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }