<template>
  <div>
    <p class="text-2xl mb-6">Banco</p>

    <v-container>
      <v-row>
        <v-col lg="12" cols="12">
          <v-card>
            <v-card-text>
              <banco-nuevo @CargarLista="CargarLista" :BancoDatos="banco"></banco-nuevo>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="12" cols="12">
          <banco-lista ref="BancoListaRef" @GetEditarBanco="GetEditarBanco"></banco-lista>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import BancoNuevo from './components/BancoNuevo.vue'
import { ref } from '@vue/composition-api'
import BancoLista from './components/BancoLista.vue' 
import config from '@/api/config'
// demos

export default {
  components: { BancoNuevo, BancoLista },
  setup() {
    const banco = ref({
      id: -1,
      nombre: '',
      telefono: '',
      codigo: '',
      id_tipo: 1,
      img_icon: '',
      img: '',
    })
    const BancoListaRef = ref(null)
    const GetEditarBanco = data => {
      banco.value = {
        ...data, 
        img_temp: config.api_imagenes + '/banco/img/' + data.img,
        img_icon_temp: config.api_imagenes + '/banco/img/' + data.img_icon,
      }
    }

    const CargarLista = () => {
      BancoListaRef.value.cargarBancos()
    }
    return {
      banco,
      CargarLista,
      GetEditarBanco,
      BancoListaRef,
    }
  },
}
</script>
